@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --primary: 200 50% 50%;
    /* Un color primario más suave */
    --primary-foreground: 0 0% 100%;
    /* Color de texto para el primario */
    --secondary: 210 70% 80%;
    /* Un color secundario más suave */
    --secondary-foreground: 240 10% 10%;
    /* Color de texto para el secundario */
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --primary: 200 50% 40%;
    /* Ajusta el color primario para el modo oscuro */
    --primary-foreground: 0 0% 100%;
    --secondary: 210 50% 60%;
    /* Ajusta el color secundario para el modo oscuro */
    --secondary-foreground: 240 5% 60%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border !scroll-smooth;
  }

  body {
    @apply bg-background text-foreground;
  }

  button {
    @apply active:outline-none;
  }
}

@layer utilities {
  .heading {
    @apply font-bold text-4xl md:text-5xl text-center;
  }

  /* Clase para ajustar el margen del título y descripción del id 3 */
  .custom-title {
    margin-top: 1rem; /* Ajusta este valor según sea necesario */
  }

  .custom-description {
    margin-top: 0.5rem; /* Ajusta este valor según sea necesario */
  }
}
